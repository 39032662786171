
// document.addEventListener("DOMContentLoaded",function(){

//NOTE: A better selector would be [data-features~="name"] which allows multiple features like data-features="feature1 feature2 feature3"]
const run = async () => {
    const lazyLoaders = [{
        selector: '[data-features~="2fa"]', loader: () => import(/* webpackChunkName: "feature-2fa" */ './2fa')
    }, {
        selector: '[data-features~="maps"], [data-feature="maps"]', loader: () => import(/* webpackChunkName: "feature-maps" */ './maps')
    }, {
        selector: '[data-features~="autoscroll"], .autoscroll', loader: () => import(/* webpackChunkName: "feature-autoscroll" */ './autoscroll')
    }, {
        selector: '[data-features~="account"], .account', loader: () => import(/* webpackChunkName: "feature-account" */ './account')
    }, {
        /* todo: move into account.js file */
        selector: '[data-features~="account-privacy"], .account-privacy', loader: () => import(/* webpackChunkName: "feature-account-privacy" */ './account/accountPrivacy.js')
    }, {
        selector: '[data-features~="conditual-logic"], .conditual-logic',
        loader: () => import(/* webpackChunkName: "feature-conditual-logic" */ './conditual-logic')
    }, {
        selector: '[data-features~="countdown"], .countdown', loader: () => import(/* webpackChunkName: "feature-countdown" */ './countdown')
    }, {
        selector: '[data-features~="form"], .form', loader: () => import(/* webpackChunkName: "feature-form" */ './form')
    }, {
        selector: '[data-features~="housingneeds"], .housingneeds', loader: () => import(/* webpackChunkName: "feature-housingneeds" */ './housingneeds')
    }, {
        selector: '[data-features~="pageareas"], .pageareas', loader: () => import(/* webpackChunkName: "feature-pageareas" */ './pageareas')
    }, {
        selector: '[data-features~="pricelist"], .pricelist', loader: () => import(/* webpackChunkName: "feature-pricelist" */ './pricelist')
    }, {
        selector: '[data-features~="projectareas"], .projectareas', loader: () => import(/* webpackChunkName: "feature-projectareas" */ './projectareas')
    }, {
        selector: '[data-features~="projectsalestatus"], .projectsalestatus',
        loader: () => import(/* webpackChunkName: "feature-projectsalestatus" */ './projectsalestatus')
    }, {
        selector: '[data-features~="projectpricelistinteractive"], .projectpricelistinteractive', loader: () => import('./projectpricelistinteractive')
    }, {
        selector: '[data-features~="rent-calculator"], .rent-calculator',
        loader: () => import(/* webpackChunkName: "feature-rent-calculator" */ './rentCalculator')
    }, {
        selector: '[data-features~="show-once"], .show-once',
        loader: () => import(/* webpackChunkName: "feature-show-once" */ './showOnce')
    }, {
        selector: '[data-features~="smallmedia"], .smallmedia', loader: () => import(/* webpackChunkName: "feature-smallmedia" */ './smallmedia')
    }, {
        selector: '[data-features~="survey"], .survey', loader: () => import(/* webpackChunkName: "feature-survey" */ './survey')
    }, {
        selector: '[data-features~="filters"]', loader: () => import(/* webpackChunkName: "feature-filters" */ './filters')
    },{
        selector: '[data-features~="project-filters"]', loader: () => import(/* webpackChunkName: "feature-projectFilters" */ './projectFilters')
    },{
        selector: '[data-features~="projects"]', loader: () => import(/* webpackChunkName: "feature-projectList" */ './projectList')
    },{
        selector: '[data-features~="project-ordering"]', loader: () => import(/* webpackChunkName: "feature-projectOrder" */ './projectOrder')
    }, {
        selector: '[data-features~="services"]', loader: () => import(/* webpackChunkName: "feature-services" */ './heroServices')
    }, {
        selector: '[data-features~="search"]', loader: () => import(/* webpackChunkName: "feature-search" */ './search')
    }]


    for(const {selector, loader} of lazyLoaders ) {
        const selectorElements = document.querySelectorAll(selector);

        if (selectorElements.length > 0) {
            for (const element of selectorElements) {

                const feature = await loader()
                feature.default({element})
            }
        }
    }
}

run();
