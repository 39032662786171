


export default (element) => {
    //initialise rent calculator on element
    console.log('Rent calculator shizzle')


    // TODO: Replace this with post-url#block-id=123

    const pageBlock = document.querySelector(window.featureAutoScrollSelector);
    if (pageBlock) {
        pageBlock.scrollIntoView();
    }

}
