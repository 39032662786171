


const gtagid = window.GTAG_ID


const addTagManagerScript = (gtagid) => {
    // Add google tag manager

    const layer = 'dataLayer'
    window[layer] = window[layer] || [];
    // Start with adding gmt to datalayer
    window[layer].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
    const dl = layer !== 'dataLayer' ? '&l=' + layer : '';
    addScript('https://www.googletagmanager.com/gtm.js?id=' + gtagid + dl)
}


const addScript = (url) => {
    const f = document.getElementsByTagName('script')[0];
    const script = document.createElement('script');
    script.async = true;
    script.src = url;
    f.parentNode.insertBefore(script, f);
}


addTagManagerScript(gtagid)

window.dataLayer = window.dataLayer || [];
const gtag = (...args) => {
    dataLayer.push(args);
}
gtag('js', new Date());

gtag('config', gtagid);

// Trigger forms with data-ga-event datatag and send as event tag
const gaEvents = document.querySelectorAll('form[data-ga-event]')
for (const gaEvent of gaEvents) {
    gaEvent.addEventListener('submit', (e) => {
        const {gaEvent, gaData} = e.target.dataset;

        const output  = gaData.split(';').reduce((acc, cur) => {
            const [key, value] = cur.split(':');
            if (!key || !value){
                return acc;
            }
            acc[key.trim()] = value.trim();
            return acc;
        }, {})

        gtag('event', gaEvent, output);

    })
}

